import { Field, Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { USER_TYPE_CLEANS, USER_TYPE_LAUNDRY } from "utils/constants";

import Button from "../../components/Button";
import api from "../../services/api";
import { emailValidationSchema } from "../login/validation_schemas";

const BackToLoginLink = () => {
  const { selectedDashboard } = useParams();

  return (
    <Link to={`/login?${selectedDashboard}`}>
      <span>Back to login</span>
    </Link>
  );
};

export const ForgotPassword = () => {
  const [submitted, setSubmitted] = useState(false);
  const [maskedPhone, setMaskedPhone] = useState(null);
  const { selectedDashboard } = useParams();

  const handleSubmit = useCallback(
    ({ email }, { setSubmitting, setErrors }) => {
      const endpoints = {
        [USER_TYPE_CLEANS]: "/v1/cleaners/user/password-reset/",
        [USER_TYPE_LAUNDRY]: "/v1/laundry/user/request_password_reset/"
      };

      api
        .post(endpoints[selectedDashboard], null, { email })
        .then(res => {
          setMaskedPhone(res.masked_phone);
          setSubmitted(true);
        })
        .catch(e => {
          const message = e?.error?.message;
          if (message) {
            setErrors({ email: message });
          }
        })
        .finally(() => setSubmitting(false));
    },
    [selectedDashboard]
  );

  if (submitted) {
    return <Submitted maskedPhone={maskedPhone} />;
  }

  return (
    <div className="fixed-centered">
      <div className="card">
        <h3 className="card-top">
          Enter your email to initiate password reset
        </h3>

        <Formik
          initialValues={{ email: "" }}
          onSubmit={handleSubmit}
          validationSchema={emailValidationSchema}
        >
          {({ errors }) => (
            <Form className="card-body">
              <label htmlFor="email">Email address</label>

              <div className="field">
                <Field type="email" name="email" id="email" autoFocus />
                {errors.email && (
                  <small className="field__error">{errors.email}</small>
                )}
              </div>

              <div className="card-actions">
                <Button type="submit" theme="solid">
                  Submit
                </Button>

                <div className="field">
                  <BackToLoginLink />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const Submitted = ({ maskedPhone }) => {
  const smsSentMessage = (
    <>
      <p>Message sent to {maskedPhone}</p>
      <p>
        It may take a few moments for the text to arrive. It's going to contain
        your new password. Please use it to login.
      </p>
    </>
  );

  const emailSentMessage = (
    <>
      <p>
        If an account exists with that email then password reset instructions
        will be on its way to it.
      </p>
      <p>
        Please remember to check your spam folder if it has not arrived and if
        all else fails contact your account manager.
      </p>
    </>
  );

  return (
    <div className="fixed-centered">
      <div className="card">
        <h3 className="card-top">Thanks!</h3>
        <div className="card-body">
          {maskedPhone ? smsSentMessage : emailSentMessage}
          <div className="card-actions">
            <BackToLoginLink />
          </div>
        </div>
      </div>
    </div>
  );
};
